import styled from 'styled-components'
import { themeMedia } from '../../../styles/theme'

export const ImageWrapper = styled.div`
  position: relative;

  .main-img {
    width: 100px;
    height: 100px;

    ${themeMedia.desktop`
      width: 100px;
      height: 100px;
    `}
  }

  .little-icon {
    position: absolute;
    right: -5px;
    width: 30px;
    height: 30px;

    ${themeMedia.desktop`
      width: 30px;
      height: 30px;
    `}
  }
`

export const Message = styled.div`
  font-size: 1rem;
  text-align: center;
  padding: 1rem 0;
`

export const Title = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  padding: ${({ isBankAccountProcess }) =>
    isBankAccountProcess ? '1rem 0' : '4rem 0 2rem'};
`
