import React from 'react'
import AgeLimitIcon from '../../../images/icon_age_limit.svg'
import ResponsibleGamblingIcon from '../../../images/icon_responsible_gamling.svg'
import { StyledImageWrapper, StyledTextWrapper, StyledWrapper } from './styles'
import { Text } from '../../atoms/text'
import { useTranslation } from '../../../context/translationProvider'

const RegisterTopBanner = () => {
  const { translate } = useTranslation()
  return (
    <StyledWrapper>
      <StyledImageWrapper>
        <img src={AgeLimitIcon} />
        <img src={ResponsibleGamblingIcon} />
      </StyledImageWrapper>
      <StyledTextWrapper>
        <Text>
          {translate('register.topBanner.ageLimit')}
        </Text>
        <Text>
          {translate('register.topBanner.responsibleGambling')}
        </Text>
      </StyledTextWrapper>
    </StyledWrapper>
  )
}

export { RegisterTopBanner }
