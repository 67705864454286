import React, { useContext, useEffect, useState } from 'react'
import { Modal } from '../../atoms/modal'
import { useTranslation } from '../../../context/translationProvider'
import { AuthContext } from '../../../context/authProvider'
import { useGroowthbookLocaleFeature } from '../../../hooks/useGrowthbook'
import { If } from '../../atoms/if'
import { VerifyPhoneForm } from '../VerifyPhoneForm'
import { VerifyEmailForm } from '../VerifyEmailForm'
import { prevalidateWithdrawal } from '../../../adapters/payments'
import storageService from '../../../services/storageService'
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'
import { Image } from '../../atoms/image'
import { Button } from '../../atoms/button'
import selfieAndIdIcon from '../../../images/icon_selfie_and_id_gray.svg'
import { CafIFrame } from '../../molecules/cafIFrame'
import { CAF_MESSAGE_CODES, FIVE_SECONDS } from '../../../utils/constants'
import { useLoading } from '../../../hooks/useLoading'
import useModal from '../../../hooks/useModal'
import {
  Content,
  VerificationMessage,
  VerificationStatusTitle,
  Wrapper,
} from './styles'
import { isMobile } from '../../../utils/generic'
import { getKYCStatusData } from '../../../adapters/kyc'

const NOT_VERIFIED = 'NOT_VERIFIED'
const VERIFIED = 'VERIFIED'
const APPROVED = 'APPROVED'

export const KYCVerificationModal = () => {
  const { translate } = useTranslation()
  const { isLoggedIn, safeSetUser, user, requiredData } =
    useContext(AuthContext)
  const { isLoading, startLoading, stopLoading } = useLoading(true)
  const { isOpen, closeModal, openModal } = useModal()

  const [mustRequestIdVerification, setMustRequestIdVerification] =
    useState(false)
  const [isPhoneVerificationFinished, setIsPhoneVerificationFinished] = 
    useState(user?.mobile_verification_status === VERIFIED)

  const requestIdVerificationFeature = useFeatureValue(
    'withdrawal_unverified_player_request_kyc'
  )

  const requestKycOnLoginIsEnabled = useFeatureIsOn('fe_request_kyc_on_login')
  
  const isModalClosable = useFeatureIsOn('fe_kyc_modal_on_login_closable')
  const hideClose = !isModalClosable

  const [idVerificationStarted, setIdVerificationStarted] = useState(false)

  const isPhoneVerificationStepEnabled = useGroowthbookLocaleFeature(
    'fe_igp_verify_phone_on_login'
  )
  const isEmailVerificationStepEnabled = useGroowthbookLocaleFeature(
    'fe_igp_verify_email_on_login'
  )

  const getUserIdVerificationStatus = async () => {
    startLoading()
    const response = await prevalidateWithdrawal()
    if (response.ok) {
      if (!requestIdVerificationFeature) {
        stopLoading()
        return
      }

      setMustRequestIdVerification(response.data?.mustRequestIdVerification)
    }
    stopLoading()
  }

  useEffect(() => {
    if (isLoggedIn) getUserIdVerificationStatus()
  }, [isLoggedIn, requestIdVerificationFeature])

  useEffect(() => {
    if (isLoggedIn && !isLoading && requestIdVerificationFeature) openModal()
  }, [isLoggedIn, requestIdVerificationFeature, isLoading])

  useEffect(() => {
    if(isPhoneVerificationFinished) return
    setIsPhoneVerificationFinished(user?.mobile_verification_status === VERIFIED)
  }, [user])

  const startIdVerification = () => {
    setIdVerificationStarted(true)
    storageService.clearIsWaitingVerification()
  }

  const IS_PHONE_NOT_VERIFIED =
    user?.mobile_verification_status === NOT_VERIFIED

  const IS_EMAIL_NOT_VERIFIED = user?.email_verification_status === NOT_VERIFIED

  const IS_SELFIE_AND_ID_REQUIRED =
    mustRequestIdVerification && !idVerificationStarted

  const SHOULD_SHOW_PHONE_VERIFICATION =
    IS_PHONE_NOT_VERIFIED &&
    !mustRequestIdVerification &&
    !isPhoneVerificationFinished &&
    isPhoneVerificationStepEnabled

  const SHOULD_SHOW_EMAIL_VERIFICATION =
    IS_EMAIL_NOT_VERIFIED &&
    isPhoneVerificationFinished &&
    !mustRequestIdVerification &&
    isEmailVerificationStepEnabled

  const fullNumber = `${user?.mobile_prefix} ${user?.mobile_number}`

  const closeCafIFrame = (status) => {
    if (status !== CAF_MESSAGE_CODES.ONBOARDING_FINISHED) return

    const poolingInterval = setInterval(async () => {
      const response = await getKYCStatusData(user.id)
      if (response?.data?.status === APPROVED) {
        clearInterval(poolingInterval)
        setMustRequestIdVerification(false)
        if (!IS_PHONE_NOT_VERIFIED && !IS_EMAIL_NOT_VERIFIED) closeModal()
      }
    }, FIVE_SECONDS)
  }

  const onValidatePhone = async () => {
    setIsPhoneVerificationFinished(true)
    safeSetUser()

    if (!IS_EMAIL_NOT_VERIFIED || !isEmailVerificationStepEnabled) closeModal()
  }

  const onValidateEmail = async () => {
    await safeSetUser()

    closeModal()
  }

  if (requiredData.acceptTc === false) return null

  if (
    !mustRequestIdVerification &&
    !SHOULD_SHOW_PHONE_VERIFICATION &&
    !SHOULD_SHOW_EMAIL_VERIFICATION
  )
    return null

  if (!requestKycOnLoginIsEnabled) return null

  return (
    <Modal
      theme="form"
      isOpen={isOpen}
      onClose={closeModal}
      title={translate('account.verification.verification')}
      hideClose={hideClose}
    >
      <If
        condition={IS_SELFIE_AND_ID_REQUIRED}
        render={() => (
          <Wrapper>
            <Content>
              <VerificationStatusTitle>
                {translate('account.WithdrawalRequestIdVerification')}
              </VerificationStatusTitle>

              <Image url={selfieAndIdIcon} />

              <VerificationMessage>
                {translate('account.WithdrawalVerificationRequestDescription')}
              </VerificationMessage>
              <VerificationMessage>
                {translate('account.WithdrawalVerificationRequestAwait')}
              </VerificationMessage>
              <Button
                expand
                style={{ 'margin-top': '2rem' }}
                onClick={startIdVerification}
              >
                {translate('common.start')}
              </Button>
            </Content>
          </Wrapper>
        )}
      />

      {mustRequestIdVerification && idVerificationStarted && (
        <CafIFrame
          minHeight={isMobile() ? '90vh' : '75vh'}
          chosenDocument={'IDENTIFICATION_AND_SELFIE'}
          closeIFrame={closeCafIFrame}
        />
      )}

      <If
        condition={SHOULD_SHOW_PHONE_VERIFICATION}
        render={() => (
          <VerifyPhoneForm
            phoneNumber={fullNumber}
            onSuccess={onValidatePhone}
          />
        )}
      />

      <If
        condition={SHOULD_SHOW_EMAIL_VERIFICATION}
        render={() => (
          <VerifyEmailForm email={user?.email} onSuccess={onValidateEmail} />
        )}
      />
    </Modal>
  )
}
