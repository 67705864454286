import lang from '../intl/lang.json'

export const Regions = {
  76: [
    {
      name: 'Lapland',
    },
    {
      name: 'North Ostrobothnia',
    },
    {
      name: 'Kainuu',
    },
    {
      name: 'North Karelia',
    },
    {
      name: 'North Savo',
    },
    {
      name: 'South Savo',
    },
    {
      name: 'South Karelia',
    },
    {
      name: 'Central Finland',
    },
    {
      name: 'South Ostrobothnia',
    },
    {
      name: 'Ostrobothnia',
    },
    {
      name: 'Central Ostrobothnia',
    },
    {
      name: 'Pirkanmaa',
    },
    {
      name: 'Satakunta',
    },
    {
      name: 'Päijät-Häme',
    },
    {
      name: 'Kanta-Häme',
    },
    {
      name: 'Kymenlaakso',
    },
    {
      name: 'Uusimaa',
    },
    {
      name: 'Southwest Finland',
    },
    {
      name: 'Åland',
    },
  ],
  31: [
    {
      name: 'Acre',
    },
    {
      name: 'Alagoas',
    },
    {
      name: 'Amapa',
    },
    {
      name: 'Amazonas',
    },
    {
      name: 'Bahia',
    },
    {
      name: 'Ceara',
    },
    {
      name: 'Distrito Federal',
    },
    {
      name: 'Espirito Santo',
    },
    {
      name: 'Goias',
    },
    {
      name: 'Maranhao',
    },
    {
      name: 'Mato Grosso',
    },
    {
      name: 'Mato Grosso do Sul',
    },
    {
      name: 'Minas Gerais',
    },
    {
      name: 'Para',
    },
    {
      name: 'Paraiba',
    },
    {
      name: 'Parana',
    },
    {
      name: 'Pernambuco',
    },
    {
      name: 'Piaui',
    },
    {
      name: 'Rio de Janeiro',
    },
    {
      name: 'Rio Grande do Norte',
    },
    {
      name: 'Rio Grande do Sul',
    },
    {
      name: 'Rondonia',
    },
    {
      name: 'Roraima',
    },
    {
      name: 'Santa Catarina',
    },
    {
      name: 'Sao Paulo',
    },
    {
      name: 'Sergipe',
    },
    {
      name: 'Tocantins',
    },
  ],
  42: [
    {
      name: 'Alberta',
    },
    {
      name: 'British Columbia',
    },
    {
      name: 'Manitoba',
    },
    {
      name: 'New Brunswick',
    },
    {
      name: 'Newfoundland and Labrador',
    },
    {
      name: 'Northwest Territories',
    },
    {
      name: 'Nova Scotia',
    },
    {
      name: 'Nunavut',
    },
    // {
    //   name: 'Ontario',
    // },
    {
      name: 'Prince Edward Island',
    },
    {
      name: 'Quebec',
    },
    {
      name: 'Saskatchewan',
    },
    {
      name: 'Yukon Territory',
    },
  ],
  47: [
    {
      name: 'Region Metropolitana',
    },
    {
      name: 'Aysén',
    },
    {
      name: 'Antofagasta',
    },
    {
      name: 'Araucanía',
    },
    {
      name: 'Atacama',
    },
    {
      name: 'Biobío',
    },
    {
      name: 'Coquimbo',
    },
    {
      name: 'Los Lagos',
    },
    {
      name: 'Los Ríos',
    },
    {
      name: 'Magallanes y Antartica Chilena',
    },
    {
      name: 'Maule',
    },
    {
      name: 'Ñuble',
    },
    {
      name: 'O’Higgins',
    },
    {
      name: 'Parinacota',
    },
    {
      name: 'Tarapacá',
    },
    {
      name: 'Valparaíso',
    },
  ],
  104: [
    {
      name: 'Andhra Pradesh',
    },
    {
      name: 'Arunachal Pradesh',
    },
    {
      name: 'Assam',
    },
    {
      name: 'Bihar',
    },
    {
      name: 'Chhattisgarh',
    },
    {
      name: 'Goa',
    },
    {
      name: 'Gujarat',
    },
    {
      name: 'Haryana',
    },
    {
      name: 'Himachal Pradesh',
    },
    {
      name: 'Jharkhand',
    },
    {
      name: 'Karnataka',
    },
    {
      name: 'Kerala',
    },
    {
      name: 'Madhya Pradesh',
    },
    {
      name: 'Maharashtra',
    },
    {
      name: 'Manipur',
    },
    {
      name: 'Meghalaya',
    },
    {
      name: 'Mizoram',
    },
    {
      name: 'Nagaland',
    },
    {
      name: 'Odisha',
    },
    {
      name: 'Punjab',
    },
    {
      name: 'Rajasthan',
    },
    {
      name: 'Sikkim',
    },
    {
      name: 'Tamil Nadu',
    },
    {
      name: 'Telangana',
    },
    {
      name: 'Tripura',
    },
    {
      name: 'Uttar Pradesh',
    },
    {
      name: 'Uttarakhand',
    },
    {
      name: 'West Bengal',
    },
    {
      name: 'Andaman and Nicobar Island',
    },
    {
      name: 'Chandigarh',
    },
    {
      name: 'Dadra and Nagar Haveli and Daman and Diu',
    },
    {
      name: 'Delhi',
    },
    {
      name: 'Ladakh',
    },
    {
      name: 'Lakshadweep',
    },
    {
      name: 'Jammu and Kashmir',
    },
    {
      name: 'Puducherry',
    },
  ],
  114: [
    {
      name: 'Hokkaidō',
    },
    {
      name: 'Tōhoku',
    },
    {
      name: 'Kantō',
    },
    {
      name: 'Chūbu',
    },
    {
      name: 'Kansai',
    },
    {
      name: 'Chūgoku',
    },
    {
      name: 'Shikoku',
    },
    {
      name: 'Kyūshū & Okinawa',
    },
  ],
  144: [
    {
      name: 'Aguascalientes',
    },
    {
      name: 'Baja California',
    },
    {
      name: 'Baja California Sur',
    },
    {
      name: 'Campeche',
    },
    {
      name: 'Chiapas',
    },
    {
      name: 'Chihuahua',
    },
    {
      name: 'Coahuila',
    },
    {
      name: 'Colima',
    },
    {
      name: 'Distrito Federal',
    },
    {
      name: 'Durango',
    },
    {
      name: 'Guanajuato',
    },
    {
      name: 'Guerrero',
    },
    {
      name: 'Hidalgo',
    },
    {
      name: 'Jalisco',
    },
    {
      name: 'Mexico',
    },
    {
      name: 'Michoacan',
    },
    {
      name: 'Morelos',
    },
    {
      name: 'Nayarit',
    },
    {
      name: 'Nuevo Leon',
    },
    {
      name: 'Oaxaca',
    },
    {
      name: 'Puebla',
    },
    {
      name: 'Queretaro',
    },
    {
      name: 'Quintana Roo',
    },
    {
      name: 'San Luis Potosi',
    },
    {
      name: 'Sinaloa',
    },
    {
      name: 'Sonora',
    },
    {
      name: 'Tabasco',
    },
    {
      name: 'Tamaulipas',
    },
    {
      name: 'Tlaxcala',
    },
    {
      name: 'Veracruz',
    },
    {
      name: 'Yucatan',
    },
    {
      name: 'Zacatecas',
    },
  ],
  175: [
    {
      name: 'Amazonas',
    },
    {
      name: 'Ancash',
    },
    {
      name: 'Apurimac',
    },
    {
      name: 'Arequipa',
    },
    {
      name: 'Ayacucho',
    },
    {
      name: 'Cajamarca',
    },
    {
      name: 'Callao',
    },
    {
      name: 'Cusco',
    },
    {
      name: 'Huancavelica',
    },
    {
      name: 'Huanuco',
    },
    {
      name: 'Ica',
    },
    {
      name: 'Junin',
    },
    {
      name: 'La Libertad',
    },
    {
      name: 'Lambayeque',
    },
    {
      name: 'Lima',
    },
    {
      name: 'Loreto',
    },
    {
      name: 'Madre de Dios',
    },
    {
      name: 'Moquegua',
    },
    {
      name: 'Pasco',
    },
    {
      name: 'Piura',
    },
    {
      name: 'Puno',
    },
    {
      name: 'San Martin',
    },
    {
      name: 'Tacna',
    },
    {
      name: 'Tumbes',
    },
    {
      name: 'Ucayali',
    },
  ],
}

export const PaymentProviders = {
  PAGSEGURO: 'PAGSEGURO',
  GIGADAT_INTERAC_CPI: 'CPI',
  GIGADAT_INTERAC_ACH: 'ACH',
  GIGADAT_INTERAC_ETO: 'ETO',
  PAY_4_FUN: 'PAY4FUN',
  PAY_4_FUN_GO_PIX: 'IX4',
  PAY_4_FUN_GO_TI: 'TB4',
  PAY_4_FUN_GO_BILLET: 'BO4',
  PAY_4_FUN_GO_ITAU: 'TB4_IT',
  PAY_4_FUN_GO_BRADESCO: 'TB4_BD',
  PAY_4_FUN_GO_SANTANDER: 'TB4_ST',
  PAY_4_FUN_GO_BANCO_DO_BRASIL: 'TB4_BB',
  PAY_4_FUN_GO_CAIXA: 'TB4_CX',
  PAYLIVRE_PIX: 'IXL',
  PAYLIVRE_BANK_TRANSFER: 'TBL',
  PAYLIVRE_BILLET: 'BOL',
  INOVAPAY_WALLET: 'INOVAPAY_WALLET',
  INOVAPAY_GATEWAY: 'INOVAPAY_GATEWAY',
  DIRECTA: 'DIRECTA',
  BOLETO: 'BL',
  BANCO_DO_BRASIL: 'BB',
  BRADESCO: 'B',
  CAIXA: 'CA',
  SANTANDER: 'SB',
  ITAU: 'I',
  SICREDI: 'SJ',
  BANCO_ORIGINAL: 'BZ',
  BANRISUL: 'UL',
  LOTERIAS_CAIXA: 'LC',
  SAFRA: 'SF',
  PIX: 'IX',
  INTERAC_ETRANSFER: 'IF',
  INTERAC_ONLINE: 'IR',
  SPEI: 'SE',
  OXXO: 'OX',
  VISA: 'VI',
  VISA_DEBIT: 'VD',
  MASTERCARD: 'MC',
  MASTERCARD_DEBIT: 'MD',
  BANCOMER: 'BV',
  BANORTE: 'BQ',
  TODITO_CASH: 'TC',
  AZTECA: 'AZ',
  WALMART: 'WA',
  ELEVEN: 'EN',
  CIRCULO: 'CU',
  AHORRO: 'FA',
  BODEGA: 'BW',
  BENAVIDES: 'FB',
  SAMS: 'SS',
  SUPERAMA: 'SU',
  WEBPAY: 'WP',
  ESTADO: 'BE',
  BANCO_DE_CHILE: 'BX',
  SANTANDER_CL: 'SC',
  ITAU_CL: 'IA',
  BICE: 'CE',
  BCI: 'CI',
  FALABELLA: 'LL',
  SECURITY: 'TY',
  CREDICHILE: 'LE',
  BCP: 'BC',
  INTERBANK: 'IB',
  BBVA: 'BP',
  CAJA_HUANCAYO: 'HC',
  WESTERN_UNION: 'WU',
  CAJA_CUSCO: 'US',
  PAGOEFECTIVO: 'EF',
  SCOTIABANK: 'ST',
  AREQUIPA: 'JA',
  ICA: 'JI',
  PIURA: 'JP',
  TRUJILLO: 'JR',
  TACNA: 'JT',
  KASNET: 'KE',
  TAMBO: 'TM',
  CAJA_VECINA: 'KV',
  SENCILLITO: 'SL',
  TBANC: 'TB',
  JCB: 'JC',
  AMEX: 'AE',
  BANK_TRANSFER_JP: 'TR',
  PHONE_PE: 'PH',
  NET_BANKING_IN: 'NB',
  UPI: 'UI',
  COIN_DIRECT_CRYPTO: 'CDC',
  MERCADO_PAGO: 'ME',
  INOVAPAY_DIRECT_PIX: 'INOVAPAY_DIRECT_PIX',
}

export const Pay4FunGoMethodIds = {
  TB4_IT: 1,
  TB4_BD: 2,
  TB4_ST: 3,
  TB4_BB: 4,
  TB4_CX: 5,
}

export const documentTypes = ['DNI', 'CE']

export const KycStatus = {
  UNVERIFIED: 'UNVERIFIED',
  APPROVED: 'APPROVED',
  REQUIRED: 'REQUIRED',
  REJECTED: 'REJECTED',
  WAITING_LIVENESS: 'WAITING_LIVENESS',
  PENDING: 'PENDING_VALIDATION',
}

export const CAF_MESSAGE_CODES = {
  FINISH_FACE_AUTH: 'FINISH_FACE_AUTH',
  CAPTURE_FAILED: 'SDK_CAPTURE_FAILED',
  INVALID: 'SDK_CAPTURE_INVALID',
  ONBOARDING_FINISHED: 'ONBOARDING_FINISHED',
}

export const PaymentStatus = {
  SUCCESS: 'success',
  ERROR: 'error',
  RESET: 'reset',
}

export const ModalTypes = {
  RECEIPT: 0,
  QR: 1,
  REGISTER: 2,
  SIMPLE: 3,
  SIMPLE2: 3.2,
  INBOX: 4,
  WITHDRAW: 5,
  FORM: 6,
  LOADING: 7,
  CAFIFRAME: 8,
  CONFIRMATION: 9,
  WITHDRAWDRAWER: 10,
  TANDC: 11,
}

export const SortTypes = {
  A_Z: 'alphabetical',
  Z_A: 'alphabeticalReversed',
  NEWEST_OLDEST: 'newestOldest',
  OLDEST_NEWEST: 'oldestNewest',
  POPULAR: 'popular',
}

export const PaymentTypes = {
  DEPOSIT: 'deposit',
  WITHDRAW: 'withdrawal',
}

export function accountType(country, Countries) {
  const defaultAccountTypes = [
    {
      name: lang.payments.accountTypes.checkings,
      code: 'C',
    },
    {
      name: lang.payments.accountTypes.savings,
      code: 'S',
    },
  ]

  switch (country) {
    case Countries?.BRAZIL:
      return [
        ...defaultAccountTypes,
        {
          name: lang.payments.accountTypes.jointCheckings,
          code: 'O',
        },
        {
          name: lang.payments.accountTypes.jointSavings,
          code: 'P',
        },
      ]

    case Countries?.CHILE:
      return [
        ...defaultAccountTypes,
        {
          name: lang.payments.accountTypes.salaryAccount,
          code: 'V',
        },
      ]

    case Countries?.COLOMBIA:
    case Countries?.PERU:
    case Countries?.URUGUAY:
    case Countries?.CHINA:
    case Countries?.CANADA:
      return defaultAccountTypes

    default:
      return undefined
  }
}

export const newSportsBookCulture = {
  pt: {
    goldenBoostEnabled: 'pt-PT',
    goldenBoostDisabled: 'pt-BR',
  },
  en: {
    goldenBoostEnabled: 'en-CA',
    goldenBoostDisabled: 'en-GB',
  },
  ca: {
    goldenBoostEnabled: 'en-CA',
    goldenBoostDisabled: 'en-GB',
  },
  pe: {
    goldenBoostEnabled: 'es-ES',
    goldenBoostDisabled: 'es-ES',
  },
  cl: {
    goldenBoostEnabled: 'es-ES',
    goldenBoostDisabled: 'es-ES',
  },
}

export const KambiLocaleSettings = {
  pt: {
    currency: 'BRL',
    locale: 'pt_BR',
    market: 'BR',
  },
  en: {
    currency: 'USD',
    locale: 'en_GB',
    market: 'GB',
  },
}

export const langTags = {
  en: 'en',
  pt: 'pt-BR',
  pe: 'es-PE',
  ca: 'en-CA',
  cl: 'es-CL',
}

export const GamesLanguages = {
  direct_integration: {
    pt: 'pt-br',
    en: 'en',
  },
  png: {
    en: 'en',
    pe: 'es',
    pt: 'pt-br',
  },
  '*:*': {
    en: 'EN',
    fi: 'FI',
    no: 'NO',
    ca: 'EN',
    pe: 'ES',
    ie: 'EN',
    nz: 'EN',
    fr: 'EN',
    cl: 'ES',
    mx: 'ES',
    pl: 'PL',
    pt: 'PT-BR',
  },
  relax: {
    en: 'en_US',
    fi: 'fi_FI',
    no: 'no_NO',
    ca: 'en_GB',
    pe: 'es_ES',
    ie: 'en_GB',
    nz: 'en_GB',
    fr: 'en_GB',
    cl: 'es_ES',
    mx: 'es_ES',
    pl: 'pl_PL',
    pt: 'pt_BR',
  },
  ISO6391: {
    en: 'en',
    ca: 'en',
    pe: 'es',
    cl: 'es',
    pt: 'pt',
  },
}

export const CASINO_PROVIDERS = {
  ORYX: 'ORYX',
  RELAX: 'RELAX',
  SMARTSOFT: 'SMARTSOFT',
  HUB88: 'HUB88',
  PLAYTECH: 'PLAYTECH',
}

export const oryxGameProvidersSupportingCashierEvent = [
  'oryx',
  'atomicslotslab',
  'indigomagic',
  'sega-sammy-creation',
  'bluberi',
  'pragmatic-play',
  'pragmatic-play-live',
]

export const monthsNames = {
  0: 'january',
  1: 'february',
  2: 'march',
  3: 'april',
  4: 'may',
  5: 'june',
  6: 'july',
  7: 'august',
  8: 'september',
  9: 'october',
  10: 'november',
  11: 'december',
}

export const MEDIA_MOUNT_PPC = 'AZ1327381618'

export const ONE_SECOND = 1000
export const THREE_SECONDS = 3 * ONE_SECOND
export const FIVE_SECONDS = 5 * ONE_SECOND
export const TEN_SECONDS = 10 * ONE_SECOND
export const FIFTEEN_SECONDS = 15 * ONE_SECOND

export const DATE_FORMATS = {
  default: 'YYYY-MM-DDTHH:mm:ss',
  default_with_miliseconds: 'YYYY-MM-DDTHH:mm:ss.SSS',
}

export const INFINITE_SCROLL_PAGE_SIZE = 18

export const FB_USER_QUERY_PARAM = '?utm_source=meta'
