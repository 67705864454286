import styled, { css } from 'styled-components'
import { themeStyles } from '../../../styles/theme'

export const StyledWrapper = styled.label`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background-color: ${({ $isChecked }) =>
    $isChecked
      ? themeStyles.colors.accentColors.primary.main
      : themeStyles.colors.lightGreys[1].main};

  height: 90px;
  border-radius: 6px;
  padding: 10px 12px 0 12px;
  box-sizing: border-box;
  gap: 6px;

  ${(props) =>
    props.$isDeposit &&
    css`
      background-color: ${themeStyles.colors.white.main};
    `}
`

export const StyledContent = styled.div`
  p {
    text-transform: uppercase;
    height: 36px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-height: 12px;
    max-height: 36px;
  }
`

export const StyledIconWrapper = styled.div`
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 50%;
  }
`
