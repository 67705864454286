import { Link } from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'
import { MenuSectionLi } from './styles'
import { getLinkRel } from '../../../utils/getLinkRel'
import useSlugs from '../../../hooks/useSlugs'

const FooterLink = (props) => {
  const { url, title, target } = props
  const [sportsSlug, liveSportsSlug] = useSlugs(['sports', 'live-sports'])

  const isSportsLink = (url) =>
    url?.includes(sportsSlug) || url?.includes(liveSportsSlug)

  const getFooterLink = () => {
    if (target === '_blank')
      return (
        <a
          aria-current="page"
          itemProp="url"
          target={target}
          rel={getLinkRel(target)}
          title={title}
          href={url}
        >
          {title}
        </a>
      )

    if (isSportsLink(url))
      return (
        <a aria-current="page" itemProp="url" title={title} href={url}>
          {title}
        </a>
      )

    return (
      <Link aria-current="page" itemProp="url" title={title} to={url}>
        {title}
      </Link>
    )
  }

  return <MenuSectionLi>{getFooterLink()}</MenuSectionLi>
}

FooterLink.defaultProps = {
  url: '',
  title: '',
  target: '',
}

FooterLink.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  target: PropTypes.string,
}

export { FooterLink }
