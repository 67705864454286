import {
  doStartRegistrationCpfCheck,
  getRegistrationCpfCheckStatus,
} from '../../../../../adapters/auth'
import { captureException } from '@sentry/browser'
import { useTranslation } from '../../../../../context/translationProvider'
import { useReCaptcha } from '../../../../../hooks/useReCaptcha'
import {
  REGISTRATION_STEP_IDS,
  REGISTRATION_STEPS,
} from '../../../../../hooks/useRegistrationSteps'
import { useWaitForPolling } from '../../../../../hooks/useWaitForPolling'

const useCAFPolling = (props) => {
  const { formProperties, setStepsDone, setCafData } = props
  const { translate } = useTranslation()
  const { setError, getValues, setValue } = formProperties

  const showCpfError = (data, localNationalId) => {
    let message = translate('error.cpfInUse')
    switch (data?.status) {
      case 'INVALID':
        message = translate('error.cpf.invalid')
        break
      case 'NOT_PROCESSED':
        message = translate('error.cpf.not_processed')
        break
      case 'DUPLICATED':
        message = translate('error.cpf.duplicated')
        break
    }
    setError('national_id', { message })
    captureException('CPF Check failed with nationalId: ' + localNationalId)
  }

  const checkCPFStatus = async (afId, nationalityId) => {
    const response = await getRegistrationCpfCheckStatus(afId, nationalityId)
    const isDonePolling = 'NOT_PROCESSED' !== response.data?.status

    return { ...response, isDonePolling }
  }

  const checkCaf = useReCaptcha(async ({ recaptchaToken, nationalId }) => {
    const localNationalId = nationalId
      ? nationalId
      : getValues('national_id').replaceAll('-', '').replaceAll('.', '')

    const startResponse = await doStartRegistrationCpfCheck({
      cpf: localNationalId,
      token: recaptchaToken,
      nationality_country_id: getValues('nationality'),
    })

    if (!startResponse.ok || !startResponse.data?.afId) {
      showCpfError(startResponse.data, localNationalId)
      return false
    }

    const { ok, data, aborted } = await waitForCPFPoll(
      startResponse?.data?.afId,
      getValues('nationality')
    )

    if (aborted) return false
    if (!ok || !data) {
      showCpfError(data, localNationalId)
      return false
    }

    if (setStepsDone) {
      const stepNum = REGISTRATION_STEPS.findIndex(
        (step) => step.id === REGISTRATION_STEP_IDS.SELFIE_AND_ID
      )
      setStepsDone((state) => ({ ...state, [stepNum]: false }))
    }

    if (data?.afId) {
      setCafData(data)
      setValue('afId', data.afId)
      setValue('onboardingId', data.onboardingId)
    } else {
      captureException(
        'CPF Check returned no data for nationalId: ' + nationalId
      )
      setCafData(null)
      setValue('afId', null)
      setValue('onboardingId', null)
    }
    return true
  }, 'CPF_CHECK')

  const waitForCPFPoll = useWaitForPolling(checkCPFStatus)
  return { checkCaf }
}

export default useCAFPolling
