import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import PhoneIcon from '../../../../../images/icon_phone_outline_gray.svg'
import { VerifyEvery60SecondsContent } from '../../../../molecules/verifyEvery60SecondsContent'
import { useTranslation } from '../../../../../context/translationProvider'
import { useFeatureValue } from '@growthbook/growthbook-react'
import {
  doRegistrationSmsSend,
  doRegistrationSmsValidation,
} from '../../../../../adapters/auth'
import { toast } from '../../../../atoms'
import {
  removeNationalIdMask,
  removePhoneMask,
} from '../../../../../helpers/mask'
import { RegisterChangeValueContent } from '../../customComponents/registerChangeValueContent'
import { CombinedMobileField } from '../../customComponents/combinedMobileField'
import { useReCaptcha } from '../../../../../hooks/useReCaptcha'
import { AlreadyDoneContent } from '../../customComponents/alreadyDoneContent'
import { formSubmitIsDisabled } from '../../../../../utils/formUtils'

const PhoneVerificationStep = (props) => {
  const {
    getValues,
    onCompleteStep,
    stepIsDone,
    formProperties,
    setMobilePrefix,
    mobileFieldData,
    onInputFocus,
    onInputBlur,
    setStepsDone,
  } = props
  const { trigger, formState: { errors } } = formProperties
  const { translate } = useTranslation()
  const duration = useFeatureValue('registration_timer_to_resend')

  const mobileNumber = getValues('mobileNumber')
  const mobilePrefix = getValues('mobilePrefix')
  const fullNumber = `${mobilePrefix} ${mobileNumber}`

  const [oldMobileNumber, setOldMobileNumber] = useState(mobileNumber)
  const [isChangingValue, setIsChangingValue] = useState(!mobileNumber)
  const [wasPhoneInUse, setWasPhoneInUse] = useState(false)

  const onResend = useReCaptcha(async ({ recaptchaToken }) => {
    if (!mobileNumber || stepIsDone) return
    const { ok, data, error } = await doRegistrationSmsSend({
      mobile_prefix: mobilePrefix,
      mobile_number: removePhoneMask(mobileNumber),
      email: getValues('email'),
      token: recaptchaToken,
    })

    const isSuccessful = ok && data?.mobileVerificationStatus === 'REQUESTED'

    if (!isSuccessful) {
      switch (error.messageCode) {
        case 42:
          setIsChangingValue(true)
          setWasPhoneInUse(true)
          toast.error(translate('register.error.mobileUsed'))
          break
        default:
          toast.error(translate('registration.error.sms.sending'))
          break
      }
    }
    return isSuccessful
  }, 'MOBILE_VERIFICATION')

  const onInputFieldDone = useReCaptcha(
    async ({ verification_code, recaptchaToken }) => {
      const national_id = getValues('national_id')

      const { ok, data, error = {} } = await doRegistrationSmsValidation({
        mobile_prefix: mobilePrefix,
        mobile_number: removePhoneMask(mobileNumber),
        verification_code,
        national_id: removeNationalIdMask(national_id),
        email: getValues('email'),
        token: recaptchaToken,
      })

      if (!ok || data?.mobileVerificationStatus !== 'VERIFIED') {
        switch (error?.messageCode) {
          case 602: 
            return toast.error(translate('register.error.sms.wrongCode'))

          default: 
            return toast.error(translate('registration.error.sms.validating'))
        }
      }
      return onCompleteStep()
    },
    'MOBILE_VERIFICATION'
  )

  const onChangePhoneNumber = async () => {
    const isMobileNumSame = mobileNumber === oldMobileNumber
    if (isMobileNumSame) {
      if (wasPhoneInUse) {
        toast.error(translate('register.error.mobileUsed'))
      } else {
        setIsChangingValue(false)
      }
      return
    }

    const isValid = await trigger('mobileNumber')
    if (!isValid) return

    setOldMobileNumber(mobileNumber)

    const isSuccessful = await onResend()
    if (isSuccessful) {
      setIsChangingValue(false)
    }
  }

  useEffect(() => {
    if (!stepIsDone) onResend()
  }, [])

  useEffect(() => {
    if (stepIsDone) return

    if (isChangingValue) {
      if (typeof window !== 'undefined') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'registrationw_phone_edit_page_viewed',
        })
      }
    } else {
      if (typeof window !== 'undefined') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'registrationw_phone_page_viewed',
        })
      }
    }
  }, [isChangingValue, stepIsDone])

  if (stepIsDone) {
    return (
      <AlreadyDoneContent
        icon={PhoneIcon}
        title={translate('register.alreadyDone.phone.title')}
        description={translate('register.alreadyDone.phone.description')}
        onNext={() => onCompleteStep()}
      />
    )
  }

  if (isChangingValue) {
    return (
      <RegisterChangeValueContent
        onSubmit={onChangePhoneNumber}
        icon={PhoneIcon}
        ctaText={translate('verification.phone.changeValue.button')}
        title={translate('verification.phone.changeValue.title')}
        description={translate('verification.phone.changeValue.description')}
        formSubmitIsDisabled={
          formSubmitIsDisabled(
            ['mobileNumber'], 
            getValues,
            errors,
          )
        }
        renderField={() => (
          <CombinedMobileField
            formProperties={formProperties}
            setMobilePrefix={setMobilePrefix}
            onInputFocus={onInputFocus}
            onInputBlur={onInputBlur}
            setStepsDone={setStepsDone}
            data={mobileFieldData}
          />
        )}
      />
    )
  }

  return (
    <VerifyEvery60SecondsContent
      onResend={onResend}
      icon={PhoneIcon}
      duration={duration}
      onInputFieldDone={onInputFieldDone}
      ctaText={translate('verification.phone.buttonText')}
      title={translate('verification.phone.title')}
      description={translate(
        'verification.phone.registerDescription'
      ).replaceAll('{phone}', fullNumber)}
      changeValueLink={translate('verification.phone.changeValue.link')}
      onChangeValueClicked={() => setIsChangingValue(true)}
      tickingText={translate('verification.phone.60seconds')}
      didntReceiveText={translate('verification.phone.clickBelow')}
    />
  )
}

PhoneVerificationStep.defaultProps = {
  getValues: () => {},
  onCompleteStep: () => {},
  stepIsDone: false,
}

PhoneVerificationStep.propTypes = {
  getValues: PropTypes.func,
  onCompleteStep: PropTypes.func,
  stepIsDone: PropTypes.bool,

  formProperties: PropTypes.object,
  setMobilePrefix: PropTypes.func,
  mobileFieldData: PropTypes.shape({
    mobilePrefix: PropTypes.string,
    phoneCodes: PropTypes.array,
  }),
  onInputFocus: PropTypes.func,
  onInputBlur: PropTypes.func,
  setStepsDone: PropTypes.func,
}

export { PhoneVerificationStep }
