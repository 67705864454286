import { useContext, useEffect } from 'react'
import { AuthContext } from '../context/authProvider'
import { GrowthBook } from '@growthbook/growthbook-react'
import { EnvConfig } from '../config/EnvConfig'
import { detectBrowser } from '../utils/generic'
import storageService from '../services/storageService'

const growthbook = new GrowthBook({
  apiHost: EnvConfig.GATSBY_GROWTHBOOK_URL,
  clientKey: EnvConfig.GATSBY_GROWTHBOOK_KEY,
  enableDevMode: true,
})

const useGrowthbookInit = () => {
  const { accessCountry } = useContext(AuthContext)

  const getOrCreateBrowserId = () => {
    let growthbookBrowserId = storageService.getValue('growthbookBrowserId')

    if (!growthbookBrowserId) {
      growthbookBrowserId = generateId()
      storageService.setValue('growthbookBrowserId', growthbookBrowserId)
    }

    return growthbookBrowserId
  }

  function generateId() {
    const randomAlphanumeric = Math.random().toString(36).substring(2, 15)
    const timestamp = Date.now().toString(36)
    return randomAlphanumeric + timestamp
  }

  useEffect(() => {
    growthbook.loadFeatures({ autoRefresh: true, timeout: 2000 })
  }, [])

  useEffect(() => {
    if (!accessCountry) return

    growthbook.setAttributes({
      pathname: window.location.pathname,
      url: window.location.href,
      userAgent: navigator.userAgent,
      browser: detectBrowser(),
      country: accessCountry,
      id: getOrCreateBrowserId(),
    })
  }, [accessCountry])

  return growthbook
}

export default useGrowthbookInit
