import React from 'react'
import PropTypes from 'prop-types'

import TimesIcon from '../../../images/icon_times_white.svg'
import ChevronLeftIcon from '../../../images/icon_chevron_left_white.svg'
import ChevronLeftIconBlack from '../../../images/icon_chevron_left_black.svg'
import TimesIconBlack from '../../../images/icon_times_black.svg'
import {
  StyledModalHeaderWrapper,
  StyledOuterModalHeaderWrapper,
} from './styles'
import { ResponsiveContainer } from '../responsiveContainer'
import { themeSizes } from '../../../styles/theme'

const ModalHeader = (props) => {
  const {
    closeModal,
    title,
    theme,
    hideBack,
    hideClose,
    isPopup,
    isTransparent,
    stickHeaderToTop,
    onBack,
    onClose,
    desktopLayoutBreakpoint,
    leftSideClose,
    isWhiteBackground,
  } = props

  if (!closeModal) return <></>

  const createImage = (src, key, shouldHide, onClick) => {
    if (shouldHide) return <div className="item" />
    return (
      <img
        className="item"
        src={src}
        alt={key}
        onClick={onClick ?? (() => closeModal(key))}
      />
    )
  }

  const backIcon = isWhiteBackground ? ChevronLeftIconBlack : ChevronLeftIcon

  return (
    <StyledOuterModalHeaderWrapper
      stickHeaderToTop={stickHeaderToTop}
      theme={theme}
      leftSideClose={leftSideClose}
      isTransparent={isTransparent}
    >
      <StyledModalHeaderWrapper
        isPopup={isPopup}
        theme={theme}
        desktopLayoutBreakpoint={desktopLayoutBreakpoint}
        hasTitle={!!title}
      >
        {createImage(backIcon, 'back', hideBack, onBack)}
        {title && <span>{title}</span>}
        <ResponsiveContainer
          sizeNumber={themeSizes[desktopLayoutBreakpoint]}
          render={() => createImage(TimesIcon, 'close', hideClose, onClose)}
          renderElse={() =>
            createImage(TimesIconBlack, 'close', hideClose, onClose)
          }
        />
      </StyledModalHeaderWrapper>
    </StyledOuterModalHeaderWrapper>
  )
}

ModalHeader.defaultProps = {
  closeModal: null,
  title: '',
  theme: '',
  hideBack: false,
  hideClose: false,
  isPopup: false,
  isTransparent: false,
  leftSideClose: false,
  stickHeaderToTop: false,
  onBack: null,
  onClose: null,
  desktopLayoutBreakpoint: 'tablet',
}

ModalHeader.propTypes = {
  closeModal: PropTypes.func,
  leftSideClose: PropTypes.bool,
  title: PropTypes.string,
  theme: PropTypes.string,
  hideBack: PropTypes.bool,
  hideClose: PropTypes.bool,
  isPopup: PropTypes.bool,
  isTransparent: PropTypes.bool,
  stickHeaderToTop: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  desktopLayoutBreakpoint: PropTypes.oneOf(['desktop', 'tablet']),
}

export { ModalHeader }
