import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { CAF_MESSAGE_CODES as CafCodes } from '../../../utils/constants'
import { IFrameWrapper, StyledLoadingIndicatorWrapper } from './styles'
import { Spinner } from '../../atoms/spinner'
import { toast } from '../../atoms/toast'
import { getKYCOnboardingData } from '../../../adapters/kyc'
import { If } from '../../atoms/if'
import { AuthContext } from '../../../context/authProvider'
import { useModalContext } from '../../../context/modalProvider'
import ConnectionFailedIcon from '../../../images/icon_connection_failed.svg'
import { ModalTypes } from '../../../utils/constants'
import { useTranslation } from '../../../context/translationProvider'
import { useGroowthbookLocaleFeature } from '../../../hooks/useGrowthbook'

export const CafIFrame = (props) => {
  const {
    chosenDocument,
    closeIFrame,
    urlToVerify,
    openOrigin,
    checkURLConnection = true,
    cafAltIframeUrl,
    shouldUpdateUser,
  } = props
  const { translate } = useTranslation()
  const [cafIframeUrl, setCafIframeUrl] = useState('')
  const { safeSetUser } = useContext(AuthContext)
  const { open: openModal } = useModalContext()
  const isCafAvailable = useGroowthbookLocaleFeature('fe_igp_caf_availability')

  const fetchIFrameURL = async () => {
    if (cafAltIframeUrl) {
      setCafIframeUrl(cafAltIframeUrl)
      return
    }

    if (urlToVerify) {
      if (checkURLConnection) checkIframeUrlConnection(urlToVerify)
      setCafIframeUrl(urlToVerify)
    } else {
      const { ok, data } = await getKYCOnboardingData(chosenDocument)

      if (!ok || !data) {
        toast.error(translate('kyc_verification.error.getting_url'))
        closeIFrame()
        return
      }
      checkIframeUrlConnection()

      setCafIframeUrl(data.url)
    }
  }

  const susbcribeToIframeMessages = (message) => {
    switch (message.data.code) {
      case CafCodes.ONBOARDING_FINISHED:
        closeIFrame(message.data.code)
        if (shouldUpdateUser) {
          safeSetUser()
        }
        break
      case CafCodes.INVALID:
      case CafCodes.FINISH_FACE_AUTH:
        closeIFrame(message.data.code)
        break
      default:
        break
    }
  }

  const openFailedConnectionModal = () => {
    openModal({
      type: ModalTypes.SIMPLE,
      title: openOrigin,
      data: {
        buttonText: translate('common.close'),
        img: ConnectionFailedIcon,
        noPadding: true,
        onButtonClick: () => closeIFrame(),
        subtitle: translate('payments.modal.livenessErrorTitle'),
        text: translate('payments.modal.livenessErrorText'),
      },
    })
  }

  const checkIframeUrlConnection = () => {
    if (!isCafAvailable) openFailedConnectionModal()
  }

  useEffect(() => {
    fetchIFrameURL()
    window.addEventListener('message', susbcribeToIframeMessages, false)

    return () => {
      window.removeEventListener('message', susbcribeToIframeMessages, false)
    }
  }, [])

  return (
    <IFrameWrapper minHeight={props.minHeight}>
      <StyledLoadingIndicatorWrapper>
        <Spinner />
      </StyledLoadingIndicatorWrapper>
      <If
        condition={cafIframeUrl}
        render={() => (
          <iframe
            src={cafIframeUrl}
            allow="camera;fullscreen;accelerometer;gyroscope;magnetometer;"
          />
        )}
      />
    </IFrameWrapper>
  )
}

CafIFrame.defaultProps = {
  accountToVerify: null,
  chosenDocument: null,
  closeIFrame: () => {},
  shouldUpdateUser: true,
  openOrigin: null,
}

CafIFrame.propTypes = {
  urlToVerify: PropTypes.string,
  chosenDocument: PropTypes.string,
  closeIFrame: PropTypes.func,
  minHeight: PropTypes.string,
  cafAltIframeUrl: PropTypes.string,
  shouldUpdateUser: PropTypes.bool,
  openOrigin: PropTypes.string,
  checkURLConnection: PropTypes.bool,
}
