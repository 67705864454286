import React from 'react'
import PropTypes from 'prop-types'

import { FormBanner } from '../formBanner'
import { RegisterContent } from '../registerContent'
import { Button } from '../../atoms/button'
import { ModalHeader } from '../../atoms/modalHeader'
import { ResponsiveContainer } from '../../atoms/responsiveContainer'
import { Text, Title } from '../../atoms/text'
import { useRegistrationSteps } from '../../../hooks/useRegistrationSteps'
import { useState, useEffect } from 'react'
import {
  StyledAnimatedWrapper,
  StyledButtonWrapper,
  StyledCancelLink,
  StyledCloseButton,
  StyledLeavingContent,
  StyledLeavingContentMobile,
  StyledLeavingText,
  StyledRegisterModalWrapper,
} from './styles'
import { useModalContext } from '../../../context/modalProvider'
import { useBannersApiData } from '../../../hooks/graphqlStaticQuery/useBannersApiData'
import { useTranslation } from '../../../context/translationProvider'
import { themeSizes } from '../../../styles/theme'
import { EnvConfig } from '../../../config/EnvConfig'
import { Script } from 'gatsby'
import useFeatures from '../../../hooks/useFeatures'

const RegisterModal = (props) => {
  const { translate } = useTranslation()
  const { close } = useModalContext()
  const registerCloseModal = useFeatures('registerCloseModal')
  const { formStep, goToNextStep, goBack, isLastStep } = useRegistrationSteps()
  const [isLeaving, setIsLeaving] = useState(false)
  const [slides] = useBannersApiData(['registration'])

  const onContinue = () => {
    setIsLeaving(false)
  }

  const onCloseButton = () => {
    if (isLeaving || !registerCloseModal) {
      close()
      setIsLeaving(false)
    } else {
      setIsLeaving(true)
    }
    window.dataLayer?.push({ event: `close_register_modal_step_${formStep}` })
  }

  useEffect(() => {
    if (isLeaving) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'registrationw_closing_confirm_page_viewed',
      })
    }
  }, [isLeaving])

  const createButtons = () => (
    <StyledButtonWrapper>
      <Button onClick={onContinue}>
        {translate('register.modal.continue')}
      </Button>
      <StyledCancelLink onClick={onCloseButton}>
        {translate('register.modal.cancel')}
      </StyledCancelLink>
    </StyledButtonWrapper>
  )

  const createMobileExitScreen = () => (
    <StyledLeavingContentMobile>
      <Title level={2}>{translate('register.modal.areYouSure')}</Title>
      <FormBanner slides={slides} />
      <Text>
        <span
          dangerouslySetInnerHTML={{
            __html: translate('register.modal.signupLeavingText'),
          }}
        ></span>
      </Text>
      {createButtons()}
    </StyledLeavingContentMobile>
  )

  const createDesktopExitScreen = () => (
    <StyledLeavingContent>
      <FormBanner slides={slides} />
      <StyledLeavingText>
        <Title level={2}>{translate('register.modal.areYouSure')}</Title>
        <Text>
          <span
            dangerouslySetInnerHTML={{
              __html: translate('register.modal.signupLeavingText'),
            }}
          ></span>
        </Text>
        {createButtons()}
      </StyledLeavingText>
    </StyledLeavingContent>
  )

  const getPrepopulatedInputs = () => {
    if (props?.prepopulatedInputs) {
      return { national_id: props?.prepopulatedInputs?.idToRegister }
    }
    return undefined
  }

  const createContent = () => {
    if (!registerCloseModal) {
      return (
        <RegisterContent
          prepopulatedInputs={getPrepopulatedInputs()}
          goToNextStep={goToNextStep}
          formStep={formStep}
          goBack={goBack}
          isLastStep={isLastStep}
          layout="modal"
        />
      )
    }

    return (
      <>
        <StyledAnimatedWrapper hide={!isLeaving}>
          <ResponsiveContainer
            sizeNumber={themeSizes.tablet}
            render={createMobileExitScreen}
            renderElse={createDesktopExitScreen}
          />
        </StyledAnimatedWrapper>
        <StyledAnimatedWrapper hide={isLeaving}>
          <RegisterContent
            prepopulatedInputs={getPrepopulatedInputs()}
            goToNextStep={goToNextStep}
            formStep={formStep}
            goBack={goBack}
            isLastStep={isLastStep}
            layout="modal"
          />
        </StyledAnimatedWrapper>
      </>
    )
  }

  return (
    <div>
      <StyledRegisterModalWrapper id="register-modal">
        <ResponsiveContainer
          sizeNumber={themeSizes.tablet}
          render={() => (
            <ModalHeader
              closeModal={onCloseButton}
              title={translate('register.title')}
              onBack={goBack}
              hideBack={isLeaving || formStep == 0}
              hideClose={isLeaving}
              stickHeaderToTop
            />
          )}
        />
        {createContent()}
        <StyledCloseButton onClick={onCloseButton} />

        <Script
          src={`https://www.google.com/recaptcha/enterprise.js?render=${EnvConfig.GATSBY_RECAPTCHA_KEY}`}
        />
      </StyledRegisterModalWrapper>
    </div>
  )
}

RegisterModal.propTypes = {
  prepopulatedInputs: PropTypes.shape({
    idToRegister: PropTypes.string.isRequired,
  }),
}

export { RegisterModal }
