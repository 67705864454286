import BCO_DO_BRASIL_S_A from '../images/banks/banco_do_brasil.svg'
import BANCO_INTER from '../images/banks/banco_inter.svg'
import BANCO_PAN from '../images/banks/banco_pan.svg'
import BANCO_BANRISUL from '../images/banks/banco_banrisul.svg'
import BANCO_BRADESCO from '../images/banks/banco_bradesco.svg'
import BANCO_C6 from '../images/banks/banco_c6.svg'
import BANCO_CAIXA from '../images/banks/banco_caixa.svg'
import BANCO_ITAU from '../images/banks/banco_itau.svg'
import BANCO_MERCADO_PAGO from '../images/banks/banco_mercado_pago.svg'
import BANCO_NEON from '../images/banks/banco_neon.svg'
import BANCO_NU from '../images/banks/banco_nu.svg'
import BANCO_PAGSEGURO from '../images/banks/banco_pagseguro.svg'
import BANCO_PICPAY from '../images/banks/banco_picpay.svg'
import BANCO_SANTANDER from '../images/banks/banco_santander.svg'
import DEFAULT_BANK from '../images/banks/icon_bank-account.svg'
import DEFAULT_ADD_BANK from '../images/banks/icon_add_bank_default.svg'

export const bankIcons = {
  'BCO DO BRASIL S.A.': BCO_DO_BRASIL_S_A,
  'BANCO INTER': BANCO_INTER,
  'BANCO PAN': BANCO_PAN,
  'BCO DO ESTADO DO RS S.A.': BANCO_BANRISUL,
  'BCO BRADESCO S.A.': BANCO_BRADESCO,
  'BCO C6 S.A.': BANCO_C6,
  'CAIXA ECONOMICA FEDERAL': BANCO_CAIXA,
  'ITAÚ UNIBANCO S.A.': BANCO_ITAU,
  'BCO ITAUCARD S.A.': BANCO_ITAU,
  'MERCADO PAGO IP LTDA.': BANCO_MERCADO_PAGO,
  'NEON PAGAMENTOS S.A. IP': BANCO_NEON,
  'NU PAGAMENTOS - IP': BANCO_NU,
  'NU PAGAMENTOS': BANCO_NU,
  'PAGSEGURO INTERNET IP S.A.': BANCO_PAGSEGURO,
  'PICPAY': BANCO_PICPAY,
  'BCO SANTANDER (BRASIL) S.A.': BANCO_SANTANDER,
}

export const getBankIcon = (bankName, isAddBankAccount) => {
  return bankIcons[bankName] || (
    isAddBankAccount ? DEFAULT_ADD_BANK : DEFAULT_BANK
  )
}
