export const getBankAccountFormFields = async (translate) => {
  const bankAccountTypes = {
    CRN: translate('payments.currentAccount'),
    PAC: translate('payment.paymentAccount'),
    SVN: translate('payment.savingsAccount'),
    // SAL: translate('payment.salaryAccount'),
  }

  const maskCreditCard = (creditCardNumber) => {
    if (!creditCardNumber) return null

    creditCardNumber = creditCardNumber.replace(/[^0-9]/g, '')

    if (creditCardNumber.length < 2) {
      return {
        value: creditCardNumber,
        maskedValue: creditCardNumber,
      }
    }

    const creditCardMaskedNumber =
      creditCardNumber.slice(0, -1) + '-' + creditCardNumber.slice(-1)

    return {
      value: creditCardNumber,
      maskedValue: creditCardMaskedNumber,
    }
  }

  const bankAccountFormFields = [
    [
      {
        name: 'bankName',
        type: 'text',
        label: translate('payments.bankName'),
        disabled: true,
      },
      {
        name: 'bankBranch',
        type: 'text',
        label: translate('payments.bankBranch'),
        placeholder: translate('payments.bankBranch'),
        minLength: 3,
        maxLength: 5,
        mask: '99999',
      },
    ],
    [
      {
        name: 'accountNumber',
        type: 'text',
        label: translate('payments.bankAccount'),
        placeholder: translate('payments.bankAccount'),
        minLength: 5,
        maxLength: 21,
        maskFunction: maskCreditCard,
      },
      {
        name: 'accountType',
        type: 'dropdown',
        label: translate('payments.accountType') || 'Account Type',
        options: Object.keys(bankAccountTypes).map((key) => ({
          value: key,
          label: `${bankAccountTypes[key]}`,
        })),
      },
    ],
  ]

  return bankAccountFormFields
}