import styled from 'styled-components'

export const StyledCardWrapper = styled.section`
  width: 100%;
  max-width: 595px;
`

import { themeMedia } from '../../../styles/theme'

export const FormStyleWrapper = styled.div`
  width: 100%;

  form {
    > div.row-fields:first-of-type {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;

      ${themeMedia.desktop`
          grid-template-columns: 2fr 1fr;
          gap: 18px;
      `}
    }

    > div.row-fields:nth-of-type(2) {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;

      ${themeMedia.desktop`
          grid-template-columns: 1fr 1fr;
          gap: 18px;
      `}
    }
  }

`