import React, { useEffect } from 'react'
import { useTranslation } from '../../../context/translationProvider'
import { Text } from '../../../atomic-components/atoms/text'

export const AllowLocation = () => {
  const { translate } = useTranslation()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer?.push({
        event: 'location_prompt'
      })
    }
  }, [])

  return (
    <>
      <Text style={{ textAlign: 'center' }}>{translate('geolocation.allowLocation')}</Text>
      <Text style={{ marginTop: '75px', textAlign: 'center' }}>
        {translate('geolocation.promptWillAppear')}
      </Text>
    </>
  )
}
