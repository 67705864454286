import React from 'react'
import { useTranslation } from '../../../context/translationProvider'
import { Text } from '../../../atomic-components/atoms/text'
import { MobileBanner } from './style'

export const WaitingLocation = () => {
  const { translate } = useTranslation()
  return (
    <>
      <Text style={{ marginTop: '15px', textAlign: 'center' }}>{translate('geolocation.clickAllow')}</Text>
      <Text style={{ marginTop: '75px', textAlign: 'center' }}>{translate('geolocation.waitingAction')}</Text>
      <MobileBanner>
        <Text style={{ textAlign: 'center' }}>{translate('geolocation.clickAllowMobile')}</Text>
      </MobileBanner>
    </>
  )
}
