import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import { StyledBackButton } from './styles'
import { removeLocaleFromURL } from '../../../utils/generic'
import { getPreviousPage } from '../../../utils/historyFunctions'
import { sendMobileAppEvent } from '../../../utils/mobileAppFunctionality'

const BackButton = (props) => {
  const { link, top, left, whiteBackground, forceLink, onClick } = props

  const goBack = () => {
    sendMobileAppEvent('goBack')
    if (onClick) return onClick()
    const safeLink = removeLocaleFromURL(link)
    if (forceLink && link) return safeLink

    const previousPage = getPreviousPage()
    navigate(previousPage || safeLink)
  }

  return (
    <StyledBackButton
      {...props}
      type="button"
      onClick={goBack}
      top={top}
      left={left}
      whiteBackground={whiteBackground}
      role="back-button"
    />
  )
}

BackButton.defaultProps = {
  link: '',
  top: '',
  left: '',
  whiteBackground: false,
  forceLink: false,
  onClick: null,
}

BackButton.propTypes = {
  link: PropTypes.string,
  top: PropTypes.string,
  left: PropTypes.string,
  whiteBackground: PropTypes.bool,
  forceLink: PropTypes.bool,
  onClick: PropTypes.func,
}

export { BackButton }
