import styled from 'styled-components'

export const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 20px;

  font-size: 14px;
  font-weight: 400;
  text-align: center;
  text-decoration-skip-ink: none;

  > * {
    text-align: center;
  }

  h2 {
    font-size: 22px;
    font-weight: 700;
  }
`