import { themeStyles, themeMedia } from '../../../styles/theme'
import styled from 'styled-components'

export const InstructionsWrapper = styled.div`
  border-radius: 8px;
  background-color: #efefef;
  padding: 15px;
  margin-bottom: 35px;
`

export const MobileBanner = styled.div`
  display: none;
  ${themeMedia.maxTablet`
    display: block;
    position: fixed;
    bottom: 50px;
    left: 50px;
    right: 50px;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    background-color: ${themeStyles.colors.white.main};
  `}
`

export const StyledTopBanner = styled.div`
  display: flex;
  min-height: 56px;
  background-color: ${themeStyles.colors.dominantGrey.main};
  padding: 0 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  button {
    height: 40px;
    display: flex;
    justify-content: center;
  }
  ${themeMedia.maxTablet`
    display: block;
    padding: 15px;
    button {
      width: 100%;
      margin-top: 10px;
    }
  `}
  p {
    margin-bottom: 0;
    color: ${themeStyles.colors.white.main};
    font-size: 12px;
  }
`