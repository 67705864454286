import styled  from 'styled-components'

export const StyledBankListWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 320px;
  overflow-y: auto;
  padding-right: 10px;
  margin-top: 10px;
`

export const StyledListHeader = styled.div`
  font-size: 17px;
  font-weight: 400;
  text-align: left;
  margin-top: 30px;
`