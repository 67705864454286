import React from 'react'
import PropTypes from 'prop-types'
import { Message, Title, ImageWrapper } from './styles'
import { useTranslation } from '../../../context/translationProvider'
import { Button } from '../../atoms/button'
import { Image } from '../../atoms/image'
import { CafIFrame } from '../../molecules/cafIFrame'
import { KycStatus } from '../../../utils/constants'

import selfieAndIdIcon from '../../../images/icon_selfie_and_id_gray.svg'
import selfieAndIdIconError from '../../../images/icon_selfie_and_id_red.svg'
import inProgressIcon from '../../../images/icon_circle_hourglass_orange.svg'
import errorIcon from '../../../images/icon_circle_error_red.svg'
import lockIcon from '../../../images/icon_lock_gray.svg'
import { If } from '../../atoms/if'

const CAFSteps = (props) => {
  const {
    onClose,
    closeCafIFrame,
    onStartVerification,
    userKycStatus,
    confirmBeforeClosing,
    confirmingClose,
    setConfirmingClose,
    waitingAltTitle,
    waitingAltDescription,
    waitingAltButtonText,
    waitingAltButtonAction,
    rejectedAltTitle,
    rejectedAltDescription,
    rejectedAltCancelButtonText,
    rejectedAltCancelButtonAction,
    startAltTitle,
    startAltDescription,
    startAltAwaitMessage,
    closeAltTitle,
    closeAltDescription,
    cafIframeUrl,
    showRejected,
    isWaitingVerification,
    setIsWaitingVerification,
    setIdVerificationStarted,
    idVerificationStarted,
    shouldUpdateUser,
    isBankAccountProcess,
    closeAltButtonText,
    forceWaiting,
  } = props
  const { translate } = useTranslation()
  const rejectedCondition = showRejected ?? userKycStatus === KycStatus.REJECTED

  const startIdVerification = () => {
    onStartVerification && onStartVerification()
    setIdVerificationStarted(true)
    setIsWaitingVerification(false)
  }

  const closeIframe = () => {
    setIsWaitingVerification(true)
    closeCafIFrame && closeCafIFrame()
  }

  const closeOrConfirmClose = () => {
    if (confirmBeforeClosing) return setConfirmingClose(true)

    onClose()
  }

  const createCloseConfirmationContent = () => (
    <>
      <Title isBankAccountProcess={isBankAccountProcess}>
        {closeAltTitle || translate('account.CloseVerificationTitle')}
      </Title>

      <ImageWrapper>
        <Image url={lockIcon} className="main-img" />
      </ImageWrapper>

      <Message>
        {closeAltDescription ||
          translate('account.CloseVerificationWithdrawalDescription')}
      </Message>
      <Button
        expand
        style={{ 'margin-top': '2rem' }}
        onClick={() => {
          startIdVerification()
          setConfirmingClose(false)
        }}
      >
        {closeAltButtonText || translate('account.verifyAccount')}
      </Button>
      <Button
        expand
        bordered
        dark
        style={{ 'margin-top': '1rem' }}
        onClick={() => {
          onClose()
          setConfirmingClose(false)
        }}
      >
        {translate('common.close')}
      </Button>
    </>
  )

  const createVerificationStartedContent = () => (
    <CafIFrame
      minHeight={'75vh'}
      chosenDocument={'IDENTIFICATION_AND_SELFIE'}
      closeIFrame={closeIframe}
      cafAltIframeUrl={cafIframeUrl}
      shouldUpdateUser={shouldUpdateUser}
    />
  )

  const createWaitingVerificationContent = () => (
    <>
      <Title isBankAccountProcess={isBankAccountProcess}>
        {waitingAltTitle ||
          translate('account.WithdrawalVerificationAwaitTitle')}
      </Title>

      <ImageWrapper>
        <Image url={selfieAndIdIcon} className="main-img" />
        <img src={inProgressIcon} className="little-icon" />
      </ImageWrapper>

      <Message>
        {waitingAltDescription ||
          translate('account.WithdrawalVerificationAwaitDescription')}
      </Message>
      
      <If condition={!forceWaiting} render={() => (
        <Button
        expand
        style={{ 'margin-top': '2rem' }}
        onClick={waitingAltButtonAction || closeOrConfirmClose}
      >
        {waitingAltButtonText || translate('common.close')}
      </Button>
      )} />
    </>
  )

  const createRejectedContent = () => (
    <>
      <Title isBankAccountProcess={isBankAccountProcess}>
        {rejectedAltTitle ||
          translate('account.WithdrawalVerificationErrorTitle')}
      </Title>

      <ImageWrapper>
        <Image url={selfieAndIdIconError} className="main-img" />
        <img src={errorIcon} className="little-icon" />
      </ImageWrapper>

      <Message>
        {rejectedAltDescription ||
          translate('account.WithdrawalVerificationErrorDescription')}
      </Message>
      <Button
        expand
        style={{ 'margin-top': '2rem' }}
        onClick={startIdVerification}
      >
        {translate('common.tryAgain')}
      </Button>
      <Button
        expand
        bordered
        dark
        style={{ 'margin-top': '1rem' }}
        onClick={rejectedAltCancelButtonAction || onClose}
      >
        {rejectedAltCancelButtonText || translate('common.later')}
      </Button>
    </>
  )

  const createContentDefault = () => (
    <>
      <Title isBankAccountProcess={isBankAccountProcess}>
        {startAltTitle || translate('account.WithdrawalRequestIdVerification')}
      </Title>

      <Image url={selfieAndIdIcon} />

      <Message>
        {startAltDescription ||
          translate('account.WithdrawalVerificationRequestDescription')}
      </Message>
      <Message>
        {startAltAwaitMessage ||
          translate('account.WithdrawalVerificationRequestAwait')}
      </Message>
      <Button
        expand
        style={{ 'margin-top': '2rem' }}
        onClick={startIdVerification}
      >
        {translate('common.start')}
      </Button>
    </>
  )

  if (confirmingClose) return createCloseConfirmationContent()

  if (idVerificationStarted && !isWaitingVerification)
    return createVerificationStartedContent()

  if (rejectedCondition) return createRejectedContent()

  if (isWaitingVerification) return createWaitingVerificationContent()

  return createContentDefault()
}

CAFSteps.propTypes = {
  onClose: PropTypes.func,
  closeCafIFrame: PropTypes.func,
  cafIframeUrl: PropTypes.string,
  showWaiting: PropTypes.bool,
  showRejected: PropTypes.bool,
  onStartVerification: PropTypes.func,
  userKycStatus: PropTypes.string,
  waitingAltTitle: PropTypes.string,
  waitingAltDescription: PropTypes.string,
  waitingAltButtonText: PropTypes.string,
  waitingAltButtonAction: PropTypes.func,
  rejectedAltTitle: PropTypes.string,
  rejectedAltDescription: PropTypes.string,
  rejectedAltCancelButtonText: PropTypes.string,
  rejectedAltCancelButtonAction: PropTypes.func,
  startAltTitle: PropTypes.string,
  startAltDescription: PropTypes.string,
  startAltAwaitMessage: PropTypes.string,
  startAltButtonText: PropTypes.string,
  closeAltTitle: PropTypes.string,
  closeAltDescription: PropTypes.string,
  confirmBeforeClosing: PropTypes.bool,
  confirmingClose: PropTypes.bool,
  setConfirmingClose: PropTypes.func,
  isWaitingVerification: PropTypes.bool,
  setIsWaitingVerification: PropTypes.func,
  idVerificationStarted: PropTypes.bool,
  setIdVerificationStarted: PropTypes.func,
  shouldUpdateUser: PropTypes.bool,
  closeAltButtonText: PropTypes.string,
  forceWaiting: PropTypes.bool,
}

export { CAFSteps }
