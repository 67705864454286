import styled from 'styled-components'

export const StyledDeleteButton = styled.button`
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  text-decoration: underline;
  font-size: 14px;
  margin: 0;
  padding: 0;
`
