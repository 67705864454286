import React, { useContext, useEffect } from 'react'
import { Button } from '../../../atomic-components/atoms/button'
import { AuthContext } from '../../../context/authProvider'
import { useTranslation } from '../../../context/translationProvider'
import { detectDeviceAndBrowser } from '../../../utils/detectDeviceAndBrowser'
import { Text } from '../../../atomic-components/atoms/text'
import { InstructionsWrapper } from './style'

export const DeniedLocation = () => {
  const { setTriggerGeolocation } = useContext(AuthContext)
  const { translate } = useTranslation()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.dataLayer?.push({
        event: 'denial_modal'
      })
    }
  }, [])

  const toggleContactUs = () => {
    if (typeof window !== 'undefined') {
      window.dataLayer?.push({
        event: 'denial_modal_cx'
      })
    }
    if (window.isIntercomOpen) window.Intercom('hide')
    else {
      window.Intercom('show')
      setTriggerGeolocation(false)
    }
  }

  const renderInstructions = () => {
    const { browser, deviceType } = detectDeviceAndBrowser()

    if (browser !== 'Unknown') {
      return displayLocationInstructions(browser, deviceType)
    } else {
      return displayLocationInstructions(browser, '')
    }
  }

  const displayLocationInstructions = (browser, deviceType) => {
    const instructions = {
      'Google Chrome': {
        Desktop: translate('geolocation.googleChrome.desktop'),
        Android: translate('geolocation.googleChrome.android'),
        iOS: translate('geolocation.googleChrome.ios'),
      },
      'Mozilla Firefox': {
        Desktop: translate('geolocation.mozilaFirefox.desktop'),
        Android: translate('geolocation.mozilaFirefox.android'),
        iOS: translate('geolocation.mozilaFirefox.ios'),
      },
      Safari: {
        Desktop: translate('geolocation.safari.desktop'),
        iOS: translate('geolocation.safari.ios'),
      },
      'Microsoft Edge': {
        Desktop: translate('geolocation.edge.desktop'),
        Android: translate('geolocation.edge.android'),
        iOS: translate('geolocation.edge.ios'),
      },
    }

    return {
      instructions: instructions[browser]?.[deviceType],
      browser,
    }
  }

  return (
    <>
      <Text style={{ marginBottom: '35px', textAlign: 'center' }}>
        {translate('geolocation.deniedInformation')}
      </Text>

      {
        renderInstructions()?.browser !== 'Unknown' &&
        <InstructionsWrapper>
          <Text style={{ marginBottom: '15px', textAlign: 'left', fontWeight: 700 }}>
            {translate('geolocation.changeSettingsOn')} {renderInstructions()?.browser}
          </Text>

          <Text
            style={{ textAlign: 'left' }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: renderInstructions()?.instructions,
              }}
            ></span>
          </Text>
        </InstructionsWrapper>
      }

      <Text style={{ marginBottom: '15px', textAlign: 'center' }}>{translate('geolocation.stillProblems')}</Text>
      
      <Button onClick={() => toggleContactUs()}>
        {translate('geolocation.contactSupport')}
      </Button>
    </>
  )
}
